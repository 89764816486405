export enum CrudEndPoint {
  COMPANY_BRANCH = 'company_branches',
  WASTE_TYPES = 'waste-types',
  COMPANIES = 'companies',
  EMPLOYEES = 'employees',
  BIN_TYPE= 'bin-types',
  POSITIONS = 'positions',
  ROLES = "roles",
  ACCESSES = "accesses",
  FLEET = 'fleets',
  VEHICLE_TYPES = 'vehicle-types',
  FUEL_TYPES = 'fuel-types',
  LOCATION_TYPES = 'location-types'
}
