import {Injectable} from "@angular/core";
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private messageService: MessageService) {
  }

  onSuccess(detail: string, summary: string) {
    this.messageService.add({severity: 'success', summary: summary, detail: detail});
  }

  onError(detail: string, summary: string) {
    this.messageService.add({severity: 'error', summary: summary, detail: detail});
  }

  onWarning(detail: string, summary: string) {
    this.messageService.add({severity: 'warn', summary: summary, detail: detail});
  }
}
