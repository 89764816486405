import {ControlValueAccessor, NgControl} from '@angular/forms';
import {inject, Injector} from "@angular/core";

export abstract class BaseControlValueAccessor<T>
  implements ControlValueAccessor {
  value: T | undefined;
  disabled: boolean = false;
  private _injector: Injector = inject(Injector);

  get invalid(): boolean {
    const ngControl: NgControl = this._injector.get(NgControl)
    return Boolean(ngControl?.control?.invalid && ngControl?.control?.touched);
  }

  onChange: (value: T) => void = () => {
  };

  onTouched: () => void = () => {
  };

  writeValue(value: T): void {
    this.value = value;
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  abstract onValueChange(value: T | string): void;
}
